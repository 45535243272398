import { Title, Content, TitleWrapper, SubTitle, ActionWrapper } from './styles'
import { ReactNode } from 'react'

interface IBannerWithImage {
  bannerButton?: ReactNode
  bannerTitle: JSX.Element | string
  description: ReactNode | string
  imageMobile: string
  imageDesktop: string
  title: string
  textColor?: string
  isVariant?: boolean
}

export const BannerWithImage = ({
  bannerTitle,
  description = '',
  imageMobile = '',
  imageDesktop = '',
  title = '',
  textColor = '',
  bannerButton,
  isVariant = false,
}: IBannerWithImage) => (
  <Content imageMobile={imageMobile} imageDesktop={imageDesktop} title={title} textColor={textColor} isVariant={isVariant}>
    <TitleWrapper style={{ color: textColor }} isVariant={isVariant}>
      <div>
        <Title>{bannerTitle}</Title>
        <SubTitle isVariant={isVariant}>{description}</SubTitle>
      </div>
      {bannerButton ? <ActionWrapper isVariant={isVariant}>{bannerButton}</ActionWrapper> : null}
    </TitleWrapper>
  </Content>
)
