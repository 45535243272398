import PublicLayout from '~/layouts/Public'
import { getHome } from '~/hooks/Home'
import { SectionWithImage, Stats, SectionPublications, Faq, NewsSection, PartnersForm } from '~/components/index'
import { ButtonV3 as Button } from '@provi/provi-components'

import { IPost, ICollapseSection, IStats } from 'types'
import { IArticles } from '~/hooks/Isa'
import { ROOT_URL } from '~/constants'

import { Main, ButtonWrapper, Title, BorderTitle } from '~/styles/pages/Home'
import { BannerWithImage } from '~/components/BannerWithImage'
import { FormButton } from '~/components/FormButton'
import CarouselBanner from '~/components/CarouselBanner/CarouselBanner'
import { partnerValues, studentValues } from '~/components/CarouselBanner/values'
import { Newsletter } from '~/components/Newsletter'

interface IHome {
  home: {
    posts: IPost[]
    homeQuestions: ICollapseSection[]
    allHomeStats: IStats[]
    homeNews: IArticles[]
  }
}

export async function getStaticProps() {
  return {
    props: {
      home: await getHome(),
    },
    revalidate: 600, // every 10 minutes (60s * 10)
  }
}

const Home = ({ home }: IHome) => (
  <PublicLayout
    title="Financiamento estudantil para transformar o acesso à educação"
    description="A Provi oferece financiamento estudantil para transformar o acesso à educação com tecnologia para pagamentos, gestão de notas e mais. Saiba mais!"
    indexable
  >
    <Main>
      <BannerWithImage
        imageMobile={`${ROOT_URL?.CLOUD_URL}/images/Home/home_banner_mobile.webp`}
        imageDesktop={`${ROOT_URL?.CLOUD_URL}/images/Home/home_banner_desktop.webp`}
        title="Imagem de um homem negro sentado em um sofá,sorrindo e utilizando um smartphone. "
        bannerButton={<FormButton />}
        bannerTitle="Inovações financeiras para a educação"
        description="Ofereça uma experiência de compra facilitada, gerencie suas vendas e tenha mais tempo para focar no que importa: o ensino."
      />

      <SectionWithImage
        image={`${ROOT_URL?.CLOUD_URL}/images/Home/provi_principia.png`}
        alt="Fotografia dos logotipos da Principia e da Provi com um sinal de soma entre eles"
        imagePosition="left"
        title="Agora a Provi faz parte da maior fintech do mercado de educação"
        isDark
        content={
          <>
            <p>
              A <strong>Principia</strong>, empresa focada em Instituições de Ensino Superior, realizou a aquisição da{' '}
              <strong>Provi</strong> após receber um investimento de R$200 milhões.
            </p>
            <br />
            <ButtonWrapper>
              <Button
                text="Saiba mais"
                color="white"
                mobileWidth="312px"
                href="https://pipelinevalor.globo.com/startups/noticia/principia-levanta-r-200-milhoes-e-compra-provi-de-olho-nos-cursos-de-pos-graduacao.ghtml"
                passHref
                onClick={() => {
                  window.open(
                    'https://pipelinevalor.globo.com/startups/noticia/principia-levanta-r-200-milhoes-e-compra-provi-de-olho-nos-cursos-de-pos-graduacao.ghtml',
                    '_blank',
                    'noreferrer noopener'
                  )
                }}
              />
            </ButtonWrapper>
          </>
        }
      />

      <CarouselBanner content={partnerValues} />

      <div id="form">
        <PartnersForm />
      </div>

      <SectionWithImage
        image={`${ROOT_URL?.CLOUD_URL}/images/Home/home_Provipay.webp`}
        alt="Fotografia de uma mulher de pele marrom e cabelo preto usando o celular."
        imageOffsetX={104}
        imageOffsetY={48}
        imagePosition="left"
        tag="PROVIPAY"
        title="Ofereça a melhor experiência de compra para seus alunos"
        content={
          <>
            <p>
              Venda seus cursos de forma simples e rápida com o ProviPay, a plataforma de checkout da Provi: do cartão de
              crédito ao financiamento no boleto, atenda todos os públicos em um só clique.
            </p>
            <br />
            <ButtonWrapper>
              <Button
                href="/provipay"
                passHref
                text="Conheça o ProviPay"
                mobileWidth="184px"
                buttonProps={{
                  href: '/provipay',
                }}
              />
            </ButtonWrapper>
          </>
        }
      />

      <NewsSection content={home.homeNews} />

      <Stats stats={home.allHomeStats} />

      <Faq title="Perguntas frequentes sobre a Provi" questions={home.homeQuestions} />

      <SectionPublications content={home.posts} />

      <Newsletter />
    </Main>
  </PublicLayout>
)

export default Home
