import { ButtonV3 as Button } from '@provi/provi-components'

const getIdPosition = (id: string) => {
  const position = document.querySelector<HTMLElement>(`#${id}`)?.offsetTop
  return position || 0
}

const scrollToPosition = (id: string) => {
  window.scrollTo({
    top: getIdPosition(id) - 72, // 72px for the Menu size
    left: 0,
    behavior: 'smooth',
  })
}

export const FormButton = () => (
  <div>
    <Button onClick={() => scrollToPosition('form')} text="Seja parceiro" mobileWidth="312px" width="221px" />
  </div>
)
