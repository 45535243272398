import { theme } from '@provi/provi-components'
import { ROOT_URL } from '~/constants'

export const partnerValues = [
  {
    title: 'Inovações financeiras para escolas e infoprodutores',
    text: 'Implemente novos métodos de pagamento, alcance mais alunos e simplifique processos.',
    cta: 'Seja parceiro',
    ctaLink: '/#form',
    ctaId: 'partner1',
    img: `${ROOT_URL?.CLOUD_URL}/images/Home/partner1.webp`,
    imgTitle: 'Destaque inovações',
    imgAlt: 'Fotografia de uma mulher usando o notebook em um café.',
    backgroundColor: `${theme.colors.bluePrimaryLight}`,
    isDark: false,
  },
  {
    title: 'ProviPay: o check-out feito para quem ensina',
    text: 'Aumente as suas vendas em até 35% e diminua as taxas de abandono no carrinho.',
    cta: 'Conheça',
    ctaLink: '/provipay',
    ctaId: 'partner3',
    img: `${ROOT_URL?.CLOUD_URL}/images/Home/partner3.webp`,
    imgTitle: 'Destaque ProviPay',
    imgAlt: 'Fotografia de dois homens conversando sobre negócios.',
    backgroundColor: `${theme.colors.bluePrimary}`,
    isDark: true,
  },
]

export const studentValues = [
  {
    title: 'É estudante? Veja como a Provi pode te ajudar',
    text: 'Facilitamos o pagamento de cursos de escolas e infoprodutores em todo o Brasil.',
    cta: 'Saiba mais',
    ctaLink: '/como-funciona',
    ctaId: 'student1',
    img: `${ROOT_URL?.CLOUD_URL}/images/Home/student1.webp`,
    imgTitle: 'Destaque estudante',
    imgAlt: 'Fotografia de uma estudante carregando livros didáticos e um fichário.',
    backgroundColor: `${theme.colors.bluePrimaryDark}`,
    isDark: true,
  },
  {
    title: 'Construímos pontes de acesso à educação',
    text: 'A Provi deseja facilitar e simplificar o acesso e o pagamento de cursos no Brasil. ',
    cta: 'Conheça ',
    ctaLink: '/como-funciona',
    ctaId: 'student3',
    img: `${ROOT_URL?.CLOUD_URL}/images/Home/student3.webp`,
    imgTitle: 'Destaque missão',
    imgAlt: 'Fotografia de um homem jovem sorrindo em uma rua movimentada.',
    backgroundColor: `${theme.colors.bluePrimary}`,
    isDark: true,
  },
]
