import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IContent {
  imageMobile: string
  imageDesktop: string
  textColor: string
  isVariant?: boolean
}

interface IHome {
  isVariant?: boolean
}

export const Content = styled.div<IContent>`
  background: ${({ imageMobile }) => imageMobile && `url(${imageMobile})`} no-repeat;
  background-color: ${({ isVariant }) => (isVariant ? `${theme.colors.bluePrimary}!important` : 'default')};
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;

  min-height: 82vh;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isVariant }) => (isVariant ? 'flex-end' : 'space-between')};
  margin: 0 auto;

  svg {
    margin: 0 auto;
    margin-bottom: 4.32%;
  }

  ${theme.breakpoints.up('md')} {
    max-width: 100vw;
    width: ${({ isVariant }) => (isVariant ? '100vw' : 'auto')};
    background: ${({ imageDesktop }) => imageDesktop && `url(${imageDesktop})`} no-repeat;
    background-size: ${({ isVariant }) => (isVariant ? 'auto 100%' : 'cover')};
    background-position-x: center;
    background-position-y: bottom;
    width: 100%;
    min-height: 82vh;

    svg {
      visibility: hidden;
    }
  }
`

export const TitleWrapper = styled.div<IHome>`
  padding: 54px 24px;

  ${theme.breakpoints.up('md')} {
    padding: ${({ isVariant }) => (isVariant ? '160px 5px 60px' : '160px 5px')};
    max-width: 1016px;
    width: 100%;
    margin: 0 auto;
  }
`

export const Title = styled.h1`
  width: 312px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.01em;
  margin-bottom: 23px;

  @media (min-width: 600px) {
    font-size: 30.83px;
    letter-spacing: -0.0025em;
  }

  ${theme.breakpoints.up('md')} {
    width: 520px;
    height: fit-content;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.005em;
  }
`

export const SubTitle = styled.p<IHome>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 312px;
  letter-spacing: 0.015em;
  margin-bottom: ${({ isVariant }) => (isVariant ? '20px' : 'default')};
  ${theme.breakpoints.up('md')} {
    font-size: 20px;
    width: 392px;
  }
`

export const ActionWrapper = styled.div<IHome>`
  display: flex;
  height: 320px;
  align-items: flex-end;

  ${theme.breakpoints.up('md')} {
    height: 100px;
    display: flex;
    ${({ isVariant }) =>
      isVariant &&
      css`
        justify-content: flex-end;
        transform: translateX(50%);
        margin-right: 45%;
      `};
  }
`
