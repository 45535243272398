import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Main = styled.main`
  min-height: 70vh;
  overflow: hidden;
`

export const CallHomeTitle = styled.h1`
  font-weight: 500;
  font-size: 27.37px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  margin-bottom: 23px;

  ${theme.breakpoints.up('md')} {
    font-size: 48.83px;
    line-height: 60px;
    width: 500px;
  }
`

export const HomeTitle = styled.h2`
  font-weight: 500;
  font-size: 27.37px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  margin-bottom: 23px;

  ${theme.breakpoints.up('md')} {
    font-size: 39.06px;
    line-height: 48px;
  }
`

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 15.84px;
  line-height: 24px;
  width: 312px;

  ${theme.breakpoints.up('md')} {
    font-size: 20px;
    width: 392px;
  }
`

export const CardsWrapper = styled.div`
  padding: 49px 0px 5px;
`

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1064px;
  padding: 0px 24px 50px;
  margin: 0 auto;

  > h2 {
    max-width: 558px;
    margin-bottom: 53px;
  }

  ${theme.breakpoints.up('md')} {
    padding: 0px 24px;
  }
`

export const Icon = styled.img``

export const ButtonWrapper = styled.div`
  ${theme.breakpoints.down('md')} {
    display: flex;
    justify-content: center;
  }
`

export const Title = styled.h1`
  font-weight: 800;
  font-size: 48px;
  line-height: 72px;
  align-items: center;
  letter-spacing: 0.015em;
`
export const BorderTitle = styled.h1`
  font-size: 48px;
  display: inline;
  color: transparent;
  -webkit-text-stroke: 2px white;
`
