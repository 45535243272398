import React, { useRef } from 'react'
import Image from 'next/legacy/image'
import Link from 'next/link'
import Carousel from 'react-multi-carousel'
import { ArrowLeft, ArrowRight } from '~/assets/svg'
import { ButtonV3 as Button } from '@provi/provi-components'
import { IDots, SkipCallbackOptions } from 'types'
import {
  CarouselStoryContainer,
  StoryContent,
  CarouselContent,
  IndicatorBox,
  IndicatorContent,
  ControlButton,
  Indicator,
  ImageSection,
  TextSection,
  StoryContainer,
} from './CarouselBanner.styles'

interface IBanner {
  text: JSX.Element | string
  title: JSX.Element | string
  cta: string
  ctaLink: string
  ctaId: string
  img: string
  imgAlt: string
  imgTitle: string
  backgroundColor: string
  isDark: boolean
}

interface ICarouselBanner {
  content: IBanner[]
}

const CarouselBanner = ({ content = [] }: ICarouselBanner) => {
  const carousel = useRef<Carousel>({} as Carousel)

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  const items = content.map((contentItem: IBanner, index: number) => (
    <StoryContainer backgroundColor={contentItem.backgroundColor} key={index.toString()}>
      <StoryContent key={index.toString()}>
        <ImageSection>
          <Image
            src={contentItem.img}
            alt={contentItem.imgAlt}
            title={contentItem.imgTitle}
            layout="responsive"
            width="362"
            height="256"
          />
        </ImageSection>
        <TextSection isDark={contentItem.isDark}>
          <h2 className="story-text">{contentItem.title}</h2>

          <p className="story-text">{contentItem.text}</p>
          <br />

          <Link href={contentItem.ctaLink} legacyBehavior>
            <Button
              className="story-text"
              text={contentItem.cta}
              color={contentItem.isDark ? 'white' : 'primary'}
              mobileWidth="256px"
              buttonProps={{ id: contentItem.ctaId }}
            />
          </Link>
        </TextSection>
      </StoryContent>
    </StoryContainer>
  ))

  const getAllDots = (currentSlide: number, goToSlide: (slide: number, skipCallbacks?: SkipCallbackOptions) => void) =>
    items.map((_content, index) => (
      <Indicator
        key={index.toString()}
        isActive={currentSlide === index || currentSlide === index - content.length || currentSlide === index + content.length}
        onClick={() => goToSlide(index + 2)}
      />
    ))

  const ButtonGroup = ({ goToSlide, ...rest }: IDots) => {
    const {
      carouselState: { currentSlide },
    } = rest
    return (
      <IndicatorBox>
        <IndicatorContent> {getAllDots(currentSlide - 2, goToSlide)} </IndicatorContent>
      </IndicatorBox>
    )
  }

  const RightArrow = ({ onClick }: any) => (
    <ControlButton className="right" type="button" onClick={() => onClick()}>
      <ArrowRight color="#FFF" />
    </ControlButton>
  )

  const LeftArrow = ({ onClick }: any) => (
    <ControlButton className="left" type="button" onClick={() => onClick()}>
      <ArrowLeft color="#FFF" />
    </ControlButton>
  )

  const carouselStory = (
    <div>
      {!!carousel.current && (
        <Carousel
          ref={carousel}
          swipeable
          draggable
          showDots={false}
          responsive={responsive}
          removeArrowOnDeviceType={['mobile', 'tablet']}
          customLeftArrow={<RightArrow />}
          customRightArrow={<LeftArrow />}
          ssr
          infinite
          transitionDuration={500}
          containerClass="carousel-container"
          customButtonGroup={<ButtonGroup goToSlide={carousel.current.goToSlide} carouselState={{ currentSlide: 2 }} />}
        >
          {items}
        </Carousel>
      )}
    </div>
  )

  return (
    <CarouselStoryContainer>
      <CarouselContent>{carouselStory}</CarouselContent>
    </CarouselStoryContainer>
  )
}

export default CarouselBanner
