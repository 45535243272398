import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IIndicator {
  isActive?: boolean
}

interface IStoryContent {
  backgroundColor: string
}

interface ITextSection {
  isDark: boolean
}

export const CarouselStoryContainer = styled.section`
  position: relative;

  @media (max-width: 875px) {
    .carousel-container ul > li {
      border-top: none;
    }
  }
`

export const CarouselContent = styled.div`
  margin: 0 auto;
  position: relative;
  height: fit-content;

  .carousel-container {
    min-height: 370px;
    align-items: flex-start;
  }
`
export const StoryContainer = styled.div<IStoryContent>`
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
  width: 100vw;
  min-height: 456px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-sizing: border-box;
`
export const StoryContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 21px;

  @media (max-width: 875px) {
    flex-direction: column;
    padding: 48px 0;
    box-sizing: border-box;
  }
`
export const ImageSection = styled.div`
  width: 362px;
  height: 256px;
  border-radius: 16px;
  overflow: hidden;

  @media (max-width: 767px) {
    width: 256px;
    height: 176px;
  }
`

export const TextSection = styled.div<ITextSection>`
  max-width: 470px;

  @media (max-width: 875px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      font-size: 16px !important;
    }
  }

  h2 {
    ${({ isDark }) =>
      isDark
        ? css`
            color: ${theme.colors.greyLight};
          `
        : css`
            color: ${theme.colors.bluePrimaryDark};
          `}
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.01em;
    }
  }

  p {
    ${({ isDark }) =>
      isDark
        ? css`
            color: ${theme.colors.white100};
          `
        : css`
            color: #000;
          `}
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.01em;
    user-select: none;

    &.story-text {
      margin-top: 24px;
    }

    @media (max-width: 875px) {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.005em;

      &.story-text {
        margin-top: 0;
      }
    }
  }
`

export const IndicatorBox = styled.div`
  width: calc(100% - 25px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 25px;
  right: auto;

  button {
    border: none;
    background-color: transparent;
  }

  @media (max-width: 875px) {
    width: 100%;
    top: unset;
    bottom: 20px;
    right: auto;
  }
`

export const IndicatorContent = styled.div`
  margin: 0 25px;
  display: flex;
`

export const ControlButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;

  svg {
    width: 50px;
    height: 50px;
    mix-blend-mode: luminosity;
  }

  &.left {
    position: absolute;
    top: 50%;
    right: 2%;
    @media (min-width: 1400px) {
      left: 1076px;
    }
  }

  &.right {
    position: absolute;
    top: 50%;
    left: 2%;
    @media (min-width: 1400px) {
      right: 1076px;
    }
  }
`

export const Indicator = styled.div<IIndicator>`
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${theme.colors.bluePrimaryDark};

  mix-blend-mode: multiply;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${theme.colors.white100};
      mix-blend-mode: normal;
      a {
        visibility: visible;
      }
    `}
`
